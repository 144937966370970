@import '../../assets/styles/variables';

.stressful {
  width: 400px;
  max-width: 100%;
  min-height: 150px;
  padding: 50px 20px;

  &__row {
    display: flex;
    align-items: center;
    //margin-bottom: 10px;
    margin: 0;
  }
}

.center {
  margin: 0 auto;
  text-align: center;
}

.title {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15px;
  color: white;
  font-weight: bold;
}

.btn {
  // display: block;
  width: 120px;
  height: 40px;
  margin: 25px 30px 0;
  border: none;
  box-shadow: -1px -1px 0 1px #dcb432 inset;
  background-color: #fff;
  border-radius: 5px;
  color: black;
  font-weight: bold;
  cursor: pointer;
  @media screen and (min-width: $breakpoint-desktop-min) {
    &:hover:not(:disabled) {
      background-color: #dcb432;
      box-shadow: 1px 1px 0 1px #966e1a inset;
    }
  }

  &.active:not(:disabled),
  &:active:not(:disabled) {
    background-color: #dcb432;
    box-shadow: 1px 1px 0 1px #966e1a inset;
  }
}

.btnCancel {
  display: block;
  margin: 25px auto 0;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.flex {
  display: flex;
  justify-content: space-evenly;
}

