@import '../../assets/styles/colors';
@import '../../assets/styles/variables';
.info {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90vw;
  height: 75vh;
  max-width: 900px;
  max-height: 800px;
  padding: 10px 6px 20px 10px;
  @media screen and (max-width: $breakpoint-mobile-landscape-max) and (orientation: portrait) {
    padding-left: 10px;
    padding-right: 0;
  }
  .header {
    padding: 0;
    margin: 0;
    list-style: none;
    color: red;
    @media screen and (max-width: $breakpoint-mobile-landscape-max) {
      flex-wrap: wrap;
    }
    .item {
      font-size: 17px;
      font-weight: bold;
      margin: 5px 10px;
      color: #616160;
      cursor: pointer;
      transition: color 0.3s ease;
      &:global(.react-tabs__tab--selected) {
        color: #f6e87e;
        cursor: default;
        outline: none;
      }
      &:hover {
        color: #fff;
      }
    }
  }
  .container {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    white-space: break-spaces;
    word-break: break-word;
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #010101;
    }
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #f6e87e;
    }
  }
}
.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
}
.title {
  color: #ff9d00;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
  white-space: break-spaces;
  word-break: break-word;
  @media screen and (max-width: $breakpoint-mobile-landscape-max) {
    font-size: 15px;
  }
  &:nth-child(n + 2) {
    margin-top: 70px;
  }
}

section {
  padding-right: 15px;
  white-space: break-spaces;
  word-break: break-word;
}
.p {
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
  @media screen and (max-width: $breakpoint-mobile-landscape-max) {
    font-size: 15px;
  }
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  span {
    color: #f6e87e;
  }
}
.p_1 {
  font-size: 20px;
  color: #fff;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 1px;
  white-space: nowrap;
  @media screen and (max-width: $breakpoint-mobile-landscape-max) {
    font-size: 15px;
  }
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  span {
    color: #f6e87e;
  }
}
.p_2 {
  font-size: 20px;
  color: #fff;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 1px;
  @media screen and (max-width: $breakpoint-mobile-landscape-max) {
    font-size: 15px;
  }
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  span {
    color: #f6e87e;
  }
}
ul {
  list-style-type: none;
  @media screen and (max-width: $breakpoint-mobile-landscape-max) {
    padding-left: 10px;
  }
}
li {
  display: inline-block;
}
.color {
  color: #f6e87e;
}
.special-combo-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .special-combo-item {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-bottom: 25px;
    .combo {
      display: flex;
      img {
        width: 33.3333%;
      }
    }
    p {
      margin: 0;
    }
  }
}

// PAY-TABLE-INFO --------------
.paytable-new-line10px {
  display: flex;
  margin-top: 10px;
}
.paytable-new-line20px {
  display: flex;
  margin-top: 20px;
}
.paytable-new-line30px {
  display: flex;
  margin-top: 30px;
}
.paytable-new-line50px {
  display: flex;
  margin-top: 50px;
}

.paytable-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  @media screen and (max-width: $breakpoint-mobile-landscape-max) {
    padding-top: 15px;
  }

  &__title {
    top: 100%;
    left: 0;
    max-width: 150px;
    font-size: 28px;
    text-align: center;
    color: #fff;
    word-break: break-word;
    white-space: break-spaces;

    @media screen and (max-width: $breakpoint-mobile-landscape-max) {
      font-size: 15px;
    }
  }

  &__item {
    display: -webkit-inline-box;
    align-items: center;
    width: 50%;
    height: auto;
    user-select: none;
    white-space: nowrap;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    margin-bottom: 20px;

    @media screen and (max-width: $breakpoint-mobile-landscape-max) {
      height: auto;
    }

    .img {
      position: relative;
      margin-right: 15px;

      @media screen and (max-width: $breakpoint-mobile-landscape-max) {
        width: 75%;
      }

      img {
        width: 150px;
        height: auto;
        max-height: 300px;

        @media screen and (max-width: $breakpoint-mobile-landscape-max) {
          width: 100%;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      font-size: 28px;
      color: #f6e87e;
      margin-top: -35px;

      @media screen and (max-width: $breakpoint-mobile-portrait-max) {
        font-size: calc(0.75em + 1vmin);
        width: 50%;
        font-size: 15px;
        margin-top: -25px;
      }
      @media screen and (max-width: $breakpoint-mobile-portrait-max) {
        font-size: calc(12px + (24 - 16) * (100vw - 450px) / (800 - 400));
        margin-top: -25px;
      }
      @media screen and (max-width: $breakpoint-mobile-portrait-lg) {
        font-size: 10px;
        margin-top: -20px;
      }
      @media screen and (max-width: $breakpoint-mobile-portrait-xs) {
        font-size: 8px;
        margin-top: -15px;
      }

      .multiplier {
        color: #fff;
      }
    }
  }
}

.paytable-list-scatter {
  display: flex;
  flex-wrap: wrap;

  &__title {
    top: 100%;
    left: 0;
    max-width: 150px;
    font-size: 28px;
    text-align: center;
    color: #fff;

    @media screen and (max-width: $breakpoint-mobile-landscape-max) {
      font-size: 15px;
    }
  }

  &__item {
    display: -webkit-inline-box;
    align-items: center;
    width: 50%;
    height: auto;
    user-select: none;
    white-space: nowrap;
    -webkit-user-select: none;
    -webkit-touch-callout: none;

    @media screen and (max-width: $breakpoint-mobile-landscape-max) {
      height: auto;
    }

    .img {
      position: relative;
      margin-right: 15px;
      word-break: break-word;
      white-space: break-spaces;

      @media screen and (max-width: $breakpoint-mobile-landscape-max) {
        width: 75%;
      }

      img {
        width: 150px;
        height: auto;
        max-height: 300px;

        @media screen and (max-width: $breakpoint-mobile-landscape-max) {
          width: 100%;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      font-size: 28px;
      color: #f6e87e;
      margin-top: -35px;

      @media screen and (max-width: $breakpoint-mobile-portrait-max) {
        font-size: calc(0.75em + 1vmin);
        width: 50%;
        font-size: 15px;
        margin-top: -25px;
      }
      @media screen and (max-width: $breakpoint-mobile-portrait-max) {
        font-size: calc(12px + (24 - 16) * (100vw - 450px) / (800 - 400));
        margin-top: -25px;
      }
      @media screen and (max-width: $breakpoint-mobile-portrait-lg) {
        font-size: 10px;
        margin-top: -20px;
      }
      @media screen and (max-width: $breakpoint-mobile-portrait-xs) {
        font-size: 8px;
        margin-top: -15px;
      }

      .multiplier {
        color: #fff;
      }
    }
  }
}

.paytable-list-expand {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  @media screen and (max-width: $breakpoint-mobile-landscape-max) {
    padding-top: 15px;
  }

  &__title {
    top: 100%;
    left: 0;
    max-width: 150px;
    font-size: 28px;
    text-align: center;
    color: #fff;

    @media screen and (max-width: $breakpoint-mobile-landscape-max) {
      font-size: 15px;
    }
  }

  &__item {
    display: -webkit-inline-box;
    align-items: center;
    width: 50%;
    height: auto;
    user-select: none;
    white-space: nowrap;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    margin-bottom: 20px;
    word-break: break-word;
    white-space: break-spaces;

    .expandimg {
      position: relative;
      margin-right: 15px;
      user-select: none;
      -webkit-user-select: none;
      -webkit-touch-callout: none;
      margin-top: auto;

      @media screen and (max-width: $breakpoint-mobile-landscape-max) {
        width: 75%;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .expandContent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      font-size: 28px;
      color: #f6e87e;
      margin-top: -25px;

      @media screen and (max-width: $breakpoint-mobile-portrait-max) {
        font-size: calc(0.75em + 1vmin);
        width: 50%;
        font-size: 15px;
        margin-top: -25px;
      }
      @media screen and (max-width: $breakpoint-mobile-portrait-max) {
        font-size: calc(12px + (24 - 16) * (100vw - 450px) / (800 - 400));
      }
      @media screen and (max-width: $breakpoint-mobile-portrait-lg) {
        font-size: 10px;
        margin-top: -20px;
      }
      @media screen and (max-width: $breakpoint-mobile-portrait-xs) {
        font-size: 8px;
        margin-top: -15px;
      }

      .multiplier {
        color: #fff;
      }
    }
  }
}

// PAY-TABLE-INFO --------------

.gameRules {
  display: block;
  width: 80%;
  margin: 0 auto;
  @media screen and (max-width: $breakpoint-mobile-portrait-max) and (orientation: portrait) {
    width: 100%;
  }
}
.payLines {
  img {
    display: block;
    max-width: 80%;
    margin: 0 auto;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    @media screen and (max-width: $breakpoint-mobile-portrait-max) and (orientation: portrait) {
      max-width: 100%;
    }
  }
}
.feature {
  padding-bottom: 2em;
  .figcontainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .fig {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1em 20px;
    p.caption {
      font-weight: 700;
      font-size: 20px;
      color: #fff;
      margin: 0;
    }
  }
  .img {
    @media screen and (max-width: $breakpoint-mobile-landscape-max) {
      width: 75%;
    }

    img {
      margin-right: 15px;
      width: 25%;
      height: auto;

      @media screen and (max-width: $breakpoint-mobile-landscape-max) {
        width: 25%;
        height: auto;
      }
    }
  }
}
.buttonBehaviors {
  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    font-weight: 700;
    color: #fff;
    img {
      width: 50px;
      margin-right: 15px;
      user-select: none;
      -webkit-user-select: none;
      -webkit-touch-callout: none;
      &.bigger {
        width: 100px;
      }
    }
    p {
      text-align: center;
    }
  }
}
